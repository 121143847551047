import React from 'react'
import SectionTitle from '../general/SectionTitle'

const MagazineHighlight = () => {
  return (
    <a href="/magazine">
      <div
        className="box has-background-light has-text-centered"
        style={{ marginBottom: 0 }}
      >
        <div className="columns">
          <div className="column">
            <div className="column has-text-centered">
              <SectionTitle highlight>عدد جديد من كتابات سودانية</SectionTitle>
              <p className="container" style={{ marginBottom: '10px' }}>
                يمكنكم الآن تنزيل العدد الأخير من مجلة كتابات سودانية (العدد
                الستون) مجانا.
              </p>
              <figure className="image is-4by5">
                <img
                  src="https://images.ssc-sudan.org/kitabat_60_efb4fa6c60.jpeg"
                  alt="مجلة كتابات سودانية"
                />
              </figure>
              <a
                href="/magazine"
                style={{ width: '100%', marginTop: 20 }}
                className="button is-success is-medium"
              >
                اقتن العدد الجديد
              </a>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default MagazineHighlight
