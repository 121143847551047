import React from 'react'
import SectionTitle from '../general/SectionTitle'

const WhatsNew = () => {
  return (
    <>
      <br />
      <section className="box has-background-light has-text-black is-light">
        <SectionTitle>أزمة الإسلام السياسي</SectionTitle>
        <p className="has-text-justified">
          الآن يمكنكم قراءة كتاب{' '}
          <a className=" is-size-6" href="books/أزمة-الاسلام-السياسي">
            أزمة الإسلام السياسي لحيدر ابراهيم علي
          </a>{' '}
          كاملا على موقع المركز. الكتاب هو أول تجربة من مشروع يود المركز من
          خلاله اتاحة عدد من كتبه واصداراته للقراءة بصورة مفتوحة على الموقع.
        </p>

        <div className="has-text-left" style={{ marginTop: 20 }}>
          <a
            className="button is-small is-outlined is-primary"
            href={'books/أزمة-الاسلام-السياسي'}
          >
            اقرأ الكتاب الآن
          </a>
        </div>
      </section>
    </>
  )
}

export default WhatsNew
