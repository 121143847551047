import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '../general/SectionTitle'
import NewsRoll from '../articles/NewsRoll'

const NewsHighlight = ({ count }) => {
  return (
    <div className="has-text-1">
      <a href="/news" className="has-text-black">
        <SectionTitle>آخر أخبار المركز</SectionTitle>
      </a>

      <div className="columns is-multiline">
        <NewsRoll
          excerptLength={100}
          count={count}
          titleStyle="content is-size-6 has-text-link"
        />
      </div>
    </div>
  )
}

NewsHighlight.propTypes = {
  count: PropTypes.number.isRequired,
}

export default NewsHighlight
