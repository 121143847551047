import React from 'react'
import PropTypes from 'prop-types'

const HomePageHero = ({ backgroundImage }) => {
  return (
    <div className="home-image-wrapper is-vcentered">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
        }}
      >
        <a
          className="button jumbotron-button"
          href="/about/عودة-مركز-الدراسات-السودانية"
        >
          اقرأ بيان عودة المركز
        </a>
      </div>
    </div>
  )
}

HomePageHero.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
}

export default HomePageHero
