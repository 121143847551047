import React from 'react'

const WhatsNew = () => {
  return (
    <div className="has-text-centered">
      <figure style={{ maxWidth: '300px', margin: 'auto', height: 'auto' }}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/watch?v=7iicoqKn4pw&list=PLhAxp6Z3aUV9MUuF-XrCemJ-iWWhsaQB6"
        >
          <img
            className=""
            src="/img/ssc_youtube.png"
            alt="قناة المركز على يوتيوب"
          />
        </a>
      </figure>
    </div>
  )
}

export default WhatsNew
