import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ArticleCard from './ArticleCard'

const HomeArticleRoll = (props) => {
  const { articles } = props
  const articleToShow = _.take(
    _.filter(articles, (article) => article.node.category !== 'أخبار'),
    9
  )

  const [
    { node: firstPost },
    { node: secondPost },
    { node: thirdPost },
    { node: fourthPost },
    { node: fifthPost },
    ...otherPosts
  ] = articleToShow

  return (
    <div className="columns is-multiline">
      {[firstPost, secondPost, thirdPost, fourthPost, fifthPost].map((post) => (
        <ArticleCard
          key={post.id}
          className="column is-full"
          excerptLength={200}
          author={post.author}
          post={post}
          showFeaturedImage
        />
      ))}

      {otherPosts &&
        otherPosts.map(({ node: post }) => (
          <ArticleCard
            key={post.id}
            className="column is-half"
            author={post.author}
            post={post}
            excerptLength={100}
            titleStyle="is-size-6"
            hideExcerpt={true}
          />
        ))}
    </div>
  )
}

HomeArticleRoll.propTypes = {
  articles: PropTypes.array,
}

export default HomeArticleRoll
