import React from 'react'
import SectionTitle from '../general/SectionTitle'

const GalleryHighlight = () => {
  const artist = {
    title: ' عرض لألوان الفنانة السودانية نجاة الماحي والفنان طارق عوض الكريم',
    featuredImage:
      'https://images.ssc-sudan.org/nagat_almahy_05_1909c5226d.jpeg',
    buttonText: 'شاهد المعرض',
  }

  return (
    <>
      <section className="card has-text-justified">
        <div className="card-image">
          <a href={artist.link || '/art'}>
            <figure className="image is-3by2">
              <img src={artist.featuredImage} alt="Artist" />
            </figure>
          </a>
        </div>

        <div className="card-content has-text-centered">
          <a href={artist.link || '/art'}>
            <SectionTitle>زاوية التشكيل</SectionTitle>
          </a>
          <div className="content">{artist.title}</div>
          <a href="/art" className="button is-primary is-outlined is-small">
            {artist.buttonText}
          </a>
        </div>
      </section>
    </>
  )
}

export default GalleryHighlight
